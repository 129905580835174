<template>
  <div class="wengao container">
    <Tips />
    <div class="article">
      <div class="article-title">{{ article.title }}</div>
      <ul class="article-info">
        <li class="source" v-if="article.source">{{ article.source }}</li>
        <li class="time">{{ article.time }}</li>
        <li>
          浏览：
          <span class="readNum">{{ article.readNum }}</span>
        </li>
      </ul>
      <div class="article-content" ref="article">
        <p v-html="article.content">{{ article.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import Article from "@/components/article";
  import { getPreviewDate } from "@/utils/api";
  import { wxShare } from "@/utils/wxShare";
  import Tips from "@/components/tips";
  export default {
    name: "previewwengao",
    components: {
      Article,
      Tips,
    },
    data() {
      return {
        canShow: false,
        id: "",
        path: "kczDetail",
        adver: {
          title: "",
          src: "",
        },
        adver2: {
          title: "",
          src: "",
        },
        article: {
          title: "",
          source: "",
          time: "",
          readNum: "",
          content: "",
        },
        share_config: {},
      };
    },
    methods: {
      async getPreviewDate(id) {
        const res = await getPreviewDate(id);
        const data = res.data.data;
        this.article.title = data.title;
        this.article.source = data.source_from;
        this.article.time = data.published_at;
        this.article.readNum = data.views;
        this.article.content = data.content;
        this.share_config = data.share_config;
        this.canShow = true;
        document.title = "预览-" + data.title;
        //wxShare(this.share_config.title, this.share_config.share_img, this.share_config.share_link, this.share_config.summary);
        wxShare("快艺学APP", "https://sharekcz.cztv.tv/cbs.png", "https://sharekcz.cztv.tv/index", "常州，教我如何不想她");
      },
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
      } else {
        this.$router.push({ name: "index" });
      }
      this.getPreviewDate(this.id);
    },
  };
</script>

<style lang="scss"></style>
